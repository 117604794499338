.tooltip {
  @apply w-[12rem] origin-[50%] whitespace-normal rounded border-transparent bg-dark-primary px-3.5 py-2.5 text-left text-[10.5px] font-normal leading-xs text-light-primary-strong opacity-100 shadow-md transition-opacity duration-300 ease-in-out;
}

.tooltip__arrow {
  @apply absolute top-auto h-[0.75rem] w-[0.75rem] rotate-45 bg-dark-primary;
}

.tooltip[data-popper-placement='top'] .tooltip__arrow {
  @apply bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2;
}

.tooltip[data-popper-placement='right'] .tooltip__arrow {
  @apply bottom-1/2 left-0 -translate-x-[45%] translate-y-1/2;
}

.tooltip[data-popper-placement='left'] .tooltip__arrow {
  @apply bottom-1/2 right-0 translate-x-[45%] translate-y-1/2;
}

.tooltip[data-popper-placement='bottom'] .tooltip__arrow {
  @apply right-1/2 -translate-y-[135%] translate-x-1/2;
}
